body #_legacy  {
  background-color: white;
  font-family: Arial, sans-serif !important;
}
#_legacy div,
#_legacy span,
#_legacy table {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
}
#_legacy .body {
  background-color: white;
  margin-right: 70px;
  font-family: Arial, sans-serif;
}
#_legacy .page {
  background-color: white;
  border-style: none;
  margin-left: 0px;
  margin-right: 0px;
}
#_legacy #content {
  margin-right: 0px;
  padding: 0;
}
#_legacy .chartWidget,
#_legacy #menu,
#_legacy #submenu,
#_legacy #menuCell,
#_legacy #footer,
#_legacy #topbar,
#_legacy #footerbar,
#_legacy #topBar #icon,
#_legacy #topBar #product,
#_legacy #betaInfo,
#_legacy .PUButtonAreaDialog,
#_legacy #dialogTopBar,
#_legacy .dialogImage,
#_legacy .actionsBox,
#_legacy .PUNetTreeContentBranch,
body #_legacy .netScrollable .PUButtonAreaDialog,
#_legacy .PUCalendarIcon,
#_legacy input[type="button"],
#_legacy .formerror,
#_legacy .search_arrow_up,
#_legacy .search_arrow_down,
#_legacy .expandPanelButton,
#_legacy .tableAction,
#_legacy .tableActionDisabled,
#_legacy #sortArrow,
#_legacy .pageCount,
#_legacy .recordCount {
  display: none;
}
#_legacy .tableActionEmail {
  text-decoration: none;
}
#_legacy .no-print {
  display: none !important;
}
#_legacy #topBar #productPrint {
  display: inline;
  float: right;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
  padding-top: 3px;
}
#_legacy .dialogBody {
  position: static;
  overflow: visible;
}
#_legacy .PUNetTreeContent {
  height: auto;
}
#_legacy .PUNetTreeContent,
#_legacy .PUNetTreeContent,
#_legacy .PUNetTreeMiddle,
#_legacy .PUNetTreeMiddle,
#_legacy .PUNetTreeContentBranch,
#_legacy .PUNetTreeLeafWrapper,
#_legacy .PUNetTreeLeafWrapper {
  float: none;
}
#_legacy .ApplicantSummary #dialogTopBar {
  display: block !important;
}
body #_legacy .netScrollable .dialogBody {
  position: static;
  overflow: visible;
}
/* Print vs. screen formatting of dates*/
#_legacy .printOnly {
  position: static;
}
#_legacy .screenOnly {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
/*Case 25388 - field size cutting off text*/
#_legacy .PUSearchField .field {
  border: 0 solid #FFFFFF;
  width: 100%;
}
/* Case 50735: Remove shadowing */
#_legacy .button,
#_legacy .innerFrame {
  -webkit-box-shadow: none;
  box-shadow: none;
}
body #_legacy ,
#_legacy #cp-header #cp-menu,
#_legacy #cp-header #cp-menu-custom,
#_legacy #logon .validation-summary-errors ul,
#_legacy #header-error,
#_legacy .body-errors,
#_legacy .validation-summary-errors ul,
#_legacy #pageup-perks #splash .description,
#_legacy #pageup-perks .intro .text,
#_legacy #pageup-perks .row .perk h2,
#_legacy #pageup-perks .row .perk p,
#_legacy .big,
#_legacy .station-node,
#_legacy .role,
#_legacy .role h1,
#_legacy .subheading,
#_legacy h1,
#_legacy h2,
#_legacy h3,
#_legacy h4,
#_legacy h5,
#_legacy h6,
#_legacy .ui-widget,
#_legacy .ui-widget input,
#_legacy .ui-widget select,
#_legacy .ui-widget textarea,
#_legacy .ui-widget button,
#_legacy #skillSearchContainer .skills ul li,
#_legacy .cp-button,
#_legacy pre,
#_legacy dt,
#_legacy .instruction,
#_legacy #submenu .right,
#_legacy #aboutLnk .right,
#_legacy input,
#_legacy textarea,
#_legacy select,
#_legacy option,
#_legacy optgroup,
#_legacy .labelExtraSmall,
#_legacy .extraSmall,
#_legacy .labelSmall,
#_legacy .labelMedium,
#_legacy .labelLarge,
#_legacy .labelExtraLarge,
#_legacy .extraLarge,
#_legacy .labelAbove,
#_legacy .leftExtraSmall,
#_legacy .leftSmall,
#_legacy .leftSmaller,
#_legacy .leftMedium,
#_legacy .leftLarge,
#_legacy .leftExtraLarge,
#_legacy .nextReviewPeriodLabels,
#_legacy .labelBold,
#_legacy .labelHeading,
#_legacy .MyDetailsLabel,
#_legacy .PopUpImageLabel,
#_legacy .PopUpLabel,
#_legacy .PopUpTitle,
#_legacy .widget .button_area a,
#_legacy .widget tr.highlight td,
#_legacy .widget .no_data,
#_legacy .widget .section .review_section .review .review_title,
#_legacy .Feedback_Item_Title,
#_legacy .Feedback_Competency_Definition,
#_legacy input.invalid_date,
#_legacy #MyPageUp .heading,
#_legacy .report_item a,
#_legacy .PUBreadcrumb a,
#_legacy .PUBreadcrumb li.selected,
#_legacy dl.plain_style dt,
#_legacy #NineBoxGrid .ratings span,
#_legacy #q_10_label span,
#_legacy .star_rating label,
#_legacy .competencyYourComment,
#_legacy .successor .rating,
#_legacy .CMScontent ul.contentList li.currentPage,
#_legacy #task-list .content,
#_legacy #task-list .hiringManagerIncomplete,
#_legacy .EditingItem h2 a,
#_legacy .Editing h2 a,
#_legacy .position_flags .hidden,
#_legacy .employee_flags .hidden,
#_legacy .flag_heading,
#_legacy .historyRow .modified,
#_legacy .lightbox-title,
#_legacy .company-objective-title,
#_legacy .company-objective-title-bold,
#_legacy .group-list a.selected,
#_legacy ul.positionCards li.positionCard h3,
#_legacy ul.positionCards ins,
#_legacy .strong,
#_legacy .action_review,
#_legacy .section-header .section-title,
#_legacy .lightbox_title,
#_legacy .cascade-objectives .section,
#_legacy #select-reviews .employee,
#_legacy .PUGrid table th a,
#_legacy .PUGridStyles table th a,
#_legacy .rt_toolbar li a,
#_legacy .rt_source_toolbar a,
#_legacy .org_successor .middle .title,
#_legacy .org_successor .middle .title a,
#_legacy #review-summary .summary-row,
#_legacy #review-header td,
#_legacy #summary-and-submit input.button,
#_legacy table.compensation td.old-max,
#_legacy table.compensation td.new,
#_legacy .employee-salary .old-max,
#_legacy .manageTasksHeading,
#_legacy td,
#_legacy th,
#_legacy span#searchText,
#_legacy .PUGrid TABLE TH #activeColumn,
#_legacy .PUGrid TABLE TH #activeColumn a,
#_legacy i,
#_legacy cite,
#_legacy em,
#_legacy var,
#_legacy address,
#_legacy dfn,
#_legacy strong,
#_legacy b,
#_legacy .PUGrid .userColumnInner,
#_legacy .employee-salary .new,
#_legacy table.compensation th.old-header,
#_legacy table.compensation th.new-header,
#_legacy table.compensation th.row-header,
#_legacy .lightboxDiv .stepContent .dueDate .label,
#_legacy .lightboxDivLarge .stepContent .dueDate .label,
#_legacy .person-card h1,
#_legacy .person-card h2,
#_legacy .person-card h3,
#_legacy .person-card h4,
#_legacy .person-card h5,
#_legacy .person-card h6,
#_legacy .person-card .home-sideinfo p label,
#_legacy .confirmation,
#_legacy .review-confirmation,
#_legacy .bold,
#_legacy .header .title,
#_legacy .review-detail-label,
#_legacy .review-summary .total-section,
#_legacy .review-summary .total-section h4,
#_legacy .banner,
#_legacy .expand-toggle,
#_legacy .activity-field label,
#_legacy .activity-field .label,
#_legacy ul.weightings-error .weighting,
#_legacy .development-questions .column .fields .dev-plan-question .question-title,
#_legacy .successor .summary a,
#_legacy div.wrapper-inner ul.people li.person {
  font-family: Helvetica, Arial, sans-serif !important;
}
#_legacy .fa {
  font-family: FontAwesome !important;
}
#_legacy .printRow {
  page-break-inside: avoid;
  display: inherit;
}
@page {
  margin: 2cm 1.22cm 2.21cm;
}
#_legacy .responseTable THEAD td {
  background-color: white;
}
#_legacy select {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
#_legacy input,
#_legacy .chosen-single {
  border: none !important;
}
/*IE*/
#_legacy select::-ms-expand {
  display: none;
}
/*Eliminate the job card's traditional border around the edit form*/
#_legacy .frame {
  border: none !important;
  padding: 0 !important;
}
/*Mixin to force the background colour to print- works on Chrome/Firefox*/
#_legacy #printable-page-title-area {
  padding: 20px;
  border: 1px solid black;
  position: relative;
  background-color: #eee !important;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
#_legacy #printable-page-title-area #printable-page-title {
  width: 70%;
  padding: 0;
}
#_legacy #printable-page-title-area #printable-page-title h1 {
  font-size: 24px;
  margin: 0;
}
#_legacy #printable-page-title-area #printable-page-info {
  margin-left: 5px;
}
#_legacy #printable-page-title-area #printable-id {
  display: block !important;
  position: absolute;
  top: 25px;
  right: 20px;
}
#_legacy #printable-page-title-area #printable-id span {
  font-size: 14px;
}
#_legacy #printable-page-title-area #printable-id span.job-number {
  font-weight: bold;
  font-size: 18px;
}
#_legacy h2 {
  font-weight: bold;
  margin-top: 20px;
  border-bottom: 1px solid black;
}
#_legacy .editJobLabel,
#_legacy .offerCardFieldLabel,
#_legacy .personalDetailsLabel,
#_legacy .labelMedium,
#_legacy .positionLabel {
  font-weight: bold;
}
#_legacy .vertical-align-top {
  vertical-align: top;
}
#_legacy .print-friendly-table {
  width: 100%;
  border-collapse: collapse;
}
#_legacy .print-friendly-table td {
  border-bottom: 1px solid black !important;
}
#_legacy .print-friendly-table thead {
  border: 1px solid black !important;
  background-color: #eee !important;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
#_legacy .print-friendly-table th {
  font-weight: bold !important;
  text-align: left;
}
#_legacy .print-friendly-table th a {
  text-decoration: none !important;
}
#_legacy .expandPanelDisabled,
#_legacy .expandPanelEnabled {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid black;
}
/*Override the hard-coded height on approvals so they only take up as much space as necessary*/
#_legacy .approvalsContainer,
#_legacy .approvalsContainer div {
  height: auto !important;
  min-height: 0 !important;
}
#_legacy .approvalsContainer:empty,
#_legacy .approvalsContainer div:empty {
  display: none !important;
}
/* ==========================================================================
   Print styles.
   Taken from: https://raw.githubusercontent.com/h5bp/html5-boilerplate/master/src/css/main.css
   ========================================================================== */
#_legacy *,
#_legacy *:before,
#_legacy *:after,
#_legacy p:first-letter,
#_legacy div:first-letter,
#_legacy blockquote:first-letter,
#_legacy li:first-letter,
#_legacy p:first-line,
#_legacy div:first-line,
#_legacy blockquote:first-line,
#_legacy li:first-line {
  background: transparent !important;
  color: #000 !important;
  /* Black prints faster:
                                http://www.sanbeiji.com/archives/953 */
  box-shadow: none !important;
  text-shadow: none !important;
}
#_legacy a,
#_legacy a:visited {
  text-decoration: underline;
}
#_legacy abbr[title]:after {
  content: " (" attr(title) ")";
}
/*
* Don't show links that are fragment identifiers,
* or use the `javascript:` pseudo protocol
*/
#_legacy a[href^="#"]:after,
#_legacy a[href^="javascript:"]:after {
  content: "";
}
#_legacy pre {
  white-space: pre-wrap !important;
}
#_legacy pre,
#_legacy blockquote {
  border: 1px solid #999;
  page-break-inside: avoid;
}
/*
* Printing Tables:
* http://css-discuss.incutio.com/wiki/Printing_Tables
*/
#_legacy thead {
  display: table-row-group;
}
#_legacy #frm_editJob tr,
#_legacy #frm_offer tr,
#_legacy .print-avoid-page-break,
#_legacy img {
  page-break-inside: avoid;
}
#_legacy p,
#_legacy h2,
#_legacy h3 {
  orphans: 3;
  widows: 3;
}
#_legacy h2,
#_legacy h3 {
  page-break-after: avoid;
}
#_legacy pre.print-friendly-area {
  display: inline !important;
  border: none;
  margin: 0px;
}
